import React, { useCallback, useEffect, useState } from "react";
import { Badge, Card, Col, Table } from "react-bootstrap";
import {
  getUserActionsList,
  userApproveApi,
  userResolvedApi,
} from "../components/APIs/Api";
import toast from "react-hot-toast";
import Pagination from "../components/Common/Pagination";
import S3Image from "../components/S3Image";
import moment from "moment";
import Filter from "../components/Filter/Filter";
import PageTitle from "../layouts/PageTitle";
import XGourdLoader from "../components/XGourdLoader";

const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), delay);
  };
};
const status = [
  { label: "Pending", value: "pending" },
  { label: "Resolved", value: "resolved" },
];
export default function UserActions() {
  const [usersList, setUsersList] = useState([]);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [statusTpe, setStatusTpe] = useState("");

  const handleSearch = useCallback(
    debounce((value) => {
      setSearch(value);
      setCurrentPage(0);
    }, 600),
    []
  );
  async function handleResolvedUser(id) {
    setLoader(true);
    try {
      const response = await userResolvedApi(id);
      toast.success("Status Resolved Successfully");
      fetchUserList();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  }
  const fetchUserList = async () => {
    setLoader(true);
    try {
      const res = await getUserActionsList(
        statusTpe,
        currentPage,
        itemsPerPage,
        search
      );
      setUsersList(res);
    } catch (err) {
      toast.error(err?.response?.data?.data || err?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  };
  const totalItems = usersList?.data?.totalRecords;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    fetchUserList();
  }, [currentPage, search, statusTpe]);

  return (
    <div>
      <PageTitle activeMenu="Actions List" motherMenu="User Actions" />

      <div className="mb-3 d-flex justify-content-between">
        <div style={{ width: "40%" }}>
          <Filter searchType="search" setSearch={handleSearch} />
        </div>
        <Filter
          filterType={statusTpe}
          setFilterType={setStatusTpe}
          FilterOption={status}
          filter="filter"
          filterTitle="Select Status . . ."
        />
      </div>

      <Col>
        {loader ? (
          <XGourdLoader />
        ) : (
          <Card>
            <Card.Body>
              <Table responsive>
                <thead style={{ color: "black" }}>
                  <tr>
                    <th>
                      <strong>NAME</strong>
                    </th>
                    <th>
                      <strong>Email</strong>
                    </th>
                    <th>
                      <strong>Type</strong>
                    </th>
                    <th>
                      <strong>message</strong>
                    </th>
                    <th>
                      <strong>Status</strong>
                    </th>
                    <th className="text-center">
                      <strong>ACTION</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {usersList?.data?.data?.map((item, i) => (
                    <tr key={item?.documents?._id}>
                      <td>
                        {item?.name?.length > 15
                          ? item?.name.slice(0, 15) + "..."
                          : item?.name || ""}
                      </td>
                      <td>{item?.email}</td>

                      <td>{item?.type}</td>
                      <td>{item?.message}</td>
                      <td>
                        <Badge
                          bg={item?.status === "pending" ? "info" : "success"}
                        >
                          {item?.status}
                        </Badge>
                      </td>
                      <td className="text-center">
                        <button
                          disabled={item?.status === "resolved" || loader}
                          className="btn btn-xs btn-primary "
                          onClick={() => handleResolvedUser(item?._id)}
                        >
                          Resolved
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {usersList?.data?.data?.length === 0 && (
                <div className="justify-content-center d-flex my-5 ">
                  Sorry, Data Not Found!
                </div>
              )}
              {usersList?.length !== 0 && (
                <div className="d-flex justify-content-between align-items-center">
                  <div className="dataTables_info">
                    Total Actions{" "}
                    <span
                      style={{
                        color: "rgb(243, 108, 3)",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {totalItems}
                    </span>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "10px" }}
                  >
                    <Pagination
                      pageCount={totalPages}
                      pageValue={currentPage}
                      setPage={setCurrentPage}
                    />
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        )}
      </Col>
    </div>
  );
}
