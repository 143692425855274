import React from "react";

const Rating = ({ rating, maxStars = 5, starImages }) => {
  const { fullStar, halfStar, emptyStar } = starImages;

  // Calculate stars based on the rating
  const fullStars = Math.floor(rating); // Full stars
  const hasHalfStar = rating % 1 !== 0; // Check if there's a half star
  const emptyStars = maxStars - fullStars - (hasHalfStar ? 1 : 0); // Remaining empty stars

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
      {/* Full stars */}
      {Array.from({ length: fullStars }).map((_, index) => (
        <img
          key={`full-${index}`}
          src={fullStar}
          alt="Full Star"
          style={{ width: "24px", height: "24px" }}
        />
      ))}

      {/* Half star */}
      {hasHalfStar && (
        <img
          src={halfStar}
          alt="Half Star"
          style={{ width: "24px", height: "24px" }}
        />
      )}

      {/* Empty stars */}
      {Array.from({ length: emptyStars }).map((_, index) => (
        <img
          key={`empty-${index}`}
          src={emptyStar}
          alt="Empty Star"
          style={{ width: "24px", height: "24px" }}
        />
      ))}
    </div>
  );
};

export default Rating;
