export const MenuList = [
  //Dashboard
  {
    title: "Dashboard",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-networking"></i>,
    to: "/dashboard",
  },

  //User Management
  {
    title: "User Management",
    classsChange: "mm-collapse",
    iconStyle: <i class="fa fa-users"></i>,
    to: "/user-management",
  },
  // Category Management
  {
    title: "Category Management",
    classsChange: "mm-collapse",
    iconStyle: <i class="flaticon-381-app"></i>,
    to: "/category-management",
  },
  {
    title: "Skill Management",
    classsChange: "mm-collapse",
    iconStyle: <i class="fa fa-regular fa-medal"></i>,
    to: "/badge-management",
  },
  {
    title: "Fee Management",
    classsChange: "mm-collapse",
    iconStyle: <i class="flaticon-381-newspaper"></i>,
    to: "/fee-management",
  },
  {
    title: "Service config",
    classsChange: "mm-collapse",
    iconStyle: <i class="flaticon-381-settings-7"></i>,
    to: "/service-management",
  },
  {
    title: "Review Management",
    classsChange: "mm-collapse",
    iconStyle: <i class="flaticon-381-star-1"></i>,
    to: "/review-management",
  },

  {
    title: "Transaction Lists",
    classsChange: "mm-collapse",
    iconStyle: <i class="flaticon-381-list-1"></i>,
    to: "/transaction-management",
  },
  {
    title: "User Actions",
    classsChange: "mm-collapse",
    iconStyle: <i class="flaticon-381-smartphone-3"></i>,
    to: "/user-actions",
  },
  {
    title: "Notifications",
    classsChange: "mm-collapse",
    iconStyle: <i class="flaticon-381-ring"></i>,
    to: "/notification-management",
  },

  {
    title: "Policies",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-settings"></i>,
    content: [
      {
        title: "Terms & Conditions",
        to: "/terms",
      },
      {
        title: "Privacy Policy",
        to: "privacy-policy",
      },
    ],
  },
];
