import React, { useCallback, useEffect, useState } from "react";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import { getFeeListApi } from "..//components/APIs/Api";
import Lottie from "lottie-react";
import animationData from "..//../images/loding.json";
import moment from "moment";
import CustomTooltip from "../components/Tooltip";
import toast from "react-hot-toast";
import EditFee from "../modal/EditFee";
import { useNavigate } from "react-router-dom";

export default function FeeManagement() {
  const [loader, setLoader] = useState(true);
  const [usersList, setUsersList] = useState();
  const [isEditModal, setIsEditModal] = useState(false);
  const [selectedFee, setSelectedFee] = useState("");
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  console.log(usersList);
  //============usersList============
  async function tableData() {
    setLoader(true);
    try {
      const res = await getFeeListApi();
      setUsersList(res);
    } catch (err) {
      toast.error(
        err?.response?.data?.data
          ? err?.response?.data?.data
          : err?.response?.data?.message
      );
    } finally {
      setLoader(false);
    }
  }
  console.log(usersList);
  useEffect(() => {
    tableData();
    scrollToTop();
  }, []);

  return (
    <>
      <div>
        <PageTitle activeMenu="Fee List" motherMenu="Fee Management" />

        <Col>
          {loader ? (
            <div className="">
              <Lottie
                style={{ height: "200px", marginTop: "45px", padding: "30px" }}
                animationData={animationData}
              />
            </div>
          ) : (
            <Card>
              <Card.Body>
                <Table responsive>
                  <thead style={{ color: "black" }}>
                    <tr>
                      <th>
                        <strong>NAME</strong>
                      </th>
                      <th className="text-center">
                        <strong>current Value</strong>
                      </th>
                      <th className="text-center">
                        <strong>Guards/agencies</strong>
                      </th>
                      <th>
                        <strong>Created at</strong>
                      </th>

                      <th className="text-center">
                        <strong>ACTION</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersList?.data?.data?.map((item, i) => (
                      <tr key={item?._id}>
                        <td>{item?.name}</td>
                        <td className="text-center">{item?.value}%</td>
                        <td
                          className="text-center hover-table"
                          onClick={() => navigate("/fee-count-users")}
                        >
                          {usersList.data.count}
                        </td>

                        <td>{moment(item?.createdAt).format("ll")}</td>

                        <td className="text-center">
                          <div className="display-flex gap">
                            <CustomTooltip content={"Edit"}>
                              <div
                                class="btn  btn-xs sharp hover-item-transition display-flex"
                                style={{
                                  backgroundColor: "#2f4cdd",
                                  color: "white",
                                }}
                                onClick={() => {
                                  setIsEditModal(true);
                                  setSelectedFee(item);
                                }}
                              >
                                <i class="fas fa-pencil-alt"></i>
                              </div>
                            </CustomTooltip>
                            <CustomTooltip content={"Details"}>
                              <div
                                class="btn btn-primary btn-xs sharp hover-item-transition display-flex"
                                onClick={() => {
                                  navigate("/fee-details", {
                                    state: item?.name,
                                  });
                                }}
                              >
                                <i class="fa fa-eye"></i>
                              </div>
                            </CustomTooltip>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {usersList?.data?.data?.length === 0 && (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                )}
              </Card.Body>
            </Card>
          )}
        </Col>
      </div>
      {isEditModal && (
        <EditFee
          show={isEditModal}
          handleClose={() => setIsEditModal(false)}
          data={selectedFee}
          refresh={tableData}
        />
      )}
    </>
  );
}
