export const colourOptions = [
  { value: "Vaccine", label: "Vaccine", color: "#00B8D9" },
  { value: "Medicines", label: "Medicines", color: "#0052CC" },
  { value: "Check ups", label: "Check ups", color: "#5243AA" },
  { value: "Xray", label: "Xray", color: "#FF5630" },
  { value: "Microchip", label: "Microchip", color: "#FF8B00" },
  { value: "In House Visit", label: "In House Visit", color: "#FF5630" },
  { value: "Grooming", label: "Grooming", color: "#FF8B00" },
];

export const limitOptions = [
  { value: "10", label: "10" },
  { value: "20", label: "20" },
  { value: "30 ", label: "30 " },
  { value: "40", label: "40" },
  { value: "50", label: "50" },
  { value: "60", label: "60" },
  { value: "70", label: "70" },
  { value: "80 ", label: "80 " },
  { value: "90", label: "90" },
  { value: "100", label: "100" },
];

export const kycOptions = [
  { label: "All", value: "" },
  { label: "Pending", value: "pending" },
  { label: "Accepted", value: "accepted" },
  { label: "Rejected", value: "rejected" },
];

export const userTypeOptions = [
  { label: "All", value: "" },
  { label: "Civilian", value: "civilian" },
  { label: "Guardian", value: "guardian" },
  { label: "Agency", value: "agency" },
];
export const configFilterLabels = [
  { value: "active", label: "Active", id: 2 },
  { value: "inactive", label: "Inactive", id: 3 },
  { value: "deleted", label: "Deleted", id: 1 },
];
export const serviceOptions = [
  { label: "All", value: "" },
  { label: "Pending", value: "pending" },
  { label: "Completed", value: "completed" },
  { label: "Rejected", value: "rejected" },
  { label: "Cancelled", value: "cancelled" },
  { label: "Accepted", value: "accepted" },
  { label: "Ongoing", value: "ongoing" },
];
