import React, { useState } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { editFeeApi } from "../components/APIs/Api";

const EditFee = ({ show, handleClose, data, refresh }) => {
  const [formData, setFormData] = useState({
    name: data?.name || "",
    value: data?.value,
  });
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let formErrors = {};

    if (!formData.name.trim()) {
      formErrors.name = "Name is required.";
    }
    if (formData.value === "") {
      formErrors.value = "Value is required.";
    } else if (isNaN(formData.value)) {
      formErrors.value = "Value must be a number.";
    } else if (formData.value < 0 || formData.value > 100) {
      formErrors.value = "Value must be between 0 and 100.";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoader(true);
      try {
        const result = await editFeeApi(formData.value, data._id);
        toast.success("Fee updated successfully");
        handleClose();
        refresh();
      } catch (e) {
        toast.error(e?.response?.data?.data || e?.response?.data?.message);
      } finally {
        setLoader(false);
      }
    } else {
      toast.error("Please fix the errors in the form.");
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Fee</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {/* Name Field */}
          <Form.Group className="mb-3" controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              name="name"
              value={formData.name}
              readOnly
              onChange={handleChange}
              isInvalid={!!errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formValue">
            <Form.Label>Value</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter value"
              name="value"
              value={formData.value}
              onChange={handleChange}
              isInvalid={!!errors.value}
              min={0}
            />
            <Form.Control.Feedback type="invalid">
              {errors.value}
            </Form.Control.Feedback>
          </Form.Group>

          {/* Submit Button */}
          <Button
            variant="primary"
            type="submit"
            disabled={loader}
            className="w-100"
          >
            {loader ? (
              <>
                <Spinner
                  animation="border"
                  size="sm"
                  title="Updating..."
                  className="me-2"
                />
                Updating...
              </>
            ) : (
              "Update"
            )}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditFee;
