import React from "react";
import "..//pages/ServiceDetails.css";
import moment from "moment";
import { Badge } from "react-bootstrap";
import starIcon from "../../images/fullStar.png";

const ServiceInfo = ({ data }) => {
  return (
    <div className="booking-details">
      <div className="d-flex justify-content-between">
        <h3 className="mb-0">Booking ID {data?.bookingId}</h3>
        <p className="m-0">
          Created At: {moment(data?.createdAt).format("ll")}
        </p>
      </div>
      {/* <p className="text-black m-0 pb-1" style={{ fontSize: "14px" }}>
        Ratings: <span>{data?.rating || "N/A"}</span>
        {data?.rating && (
          <img
            style={{ paddingRight: "6px", marginLeft: "5px" }}
            src={starIcon}
            width={20}
          />
        )}
      </p> */}
      {data.status === "Completed" ? (
        <Badge bg="success">Completed</Badge>
      ) : data.status === "rejected" ? (
        <Badge bg="danger light">Rejected</Badge>
      ) : (
        <Badge bg="info light">Pending</Badge>
      )}

      <div className="details-section">
        <h4 className="font-w600">Service Details</h4>
        <div className="details">
          <p>
            <strong>Address: </strong>
            {data?.address}
          </p>

          <p>
            <strong>Date:</strong> {moment(data?.date).format("ll")}
          </p>
          <p>
            <strong>Time:</strong>{" "}
            {moment(data?.startTime, "HH:mm").format("h:mm:ss A")} -{" "}
            {moment(data?.endTime, "HH:mm").format("h:mm A")}
          </p>
          <p>
            <strong>Cancel Percentage:</strong> {data?.cancelPercentage}%
          </p>
          <p>
            <strong>Number of guards:</strong> {data?.numberOfGuards}
          </p>
          {/* <p>
            <strong>Status:</strong> {data?.status}
          </p> */}
        </div>
      </div>
      <div className="details-section">
        <h4 className="font-w600">Earning Details</h4>
        <div className="details">
          <p>
            <strong>Admin Earning:</strong> ${data?.adminEarning}
          </p>
          <p>
            <strong>Earning from costumer:</strong> ${data?.earningFromCustomer}
          </p>
          <p>
            <strong>Earning from agency:</strong> $
            {data?.earningFromGuardAgency}
          </p>
        </div>
        <div className="details-section">
          <h4 className="font-w600">Payment Details</h4>
          <div className="details">
            <p>
              <strong>Amount: </strong>${data.amount}
            </p>
            <p>
              <strong>Tax:</strong> ${data.tax}
            </p>
            <p>
              <strong>Total:</strong> ${data.total}
            </p>

            {data.paymentMethod && (
              <p>
                <strong>Payment Method:</strong> {data?.paymentMethodId}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceInfo;
